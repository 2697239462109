<template>
  <div>
    <div style="margin-bottom:20px;">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/projectManagement' }">组合项目管理</el-breadcrumb-item>
        <el-breadcrumb-item>新增组合项目</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card shadow="always" class="add-container">
      <div class="title">新增组合项目</div>
      <div class="container">
        <div class="container__title">
          <div>选择股票</div>
          <div>(进入开仓)</div>
        </div>
        <div class="container__input" style="display:inline;">
          <el-checkbox-group v-model="checkedStockGroup" @change="handleCheckedStockChange">
            <el-checkbox v-for="stock in stockGroup" :label="stock" :key="stock.stockCode">{{stock.stockName}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="btn-group">
        <div class="btn submit" @click="submit">提交</div>
        <div class="btn cancel" @click="cancel">取消</div>
      </div>

    </el-card>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { reactive, toRefs, getCurrentInstance, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const state = reactive({
      tenantName: '',
      isAlreadyExistProduct: '1',
      checkedStockGroup: [],
      stockGroup: []
    })
    onMounted(() => {
      getStockList()
    })
    const getStockList = () => {
      proxy.$get('/cts/back/projectPortfolioManagement/stockList').then((res) => {
        if (res.resultState === '200') {
          state.stockGroup = res.data
        }
      }).catch((err) => {

      });
    }
    const handleCheckedStockChange = () => {
      console.log(state.checkedStockGroup);
    }
    const submit = () => {
      if (state.checkedStockGroup.length === 0) {
        ElMessage({
          message: '请选择股票',
          type: 'warning',
        })
        return;
      }
      proxy.$post('/cts/back/projectPortfolioManagement/addGroupProject', state.checkedStockGroup).then((res) => {
        if (res.resultState === '200') {
          ElMessage({
            message: `${res.msg}`,
            type: 'success',
          })
          router.push({ path: '/projectManagement' })
        }
      }).catch((err) => {

      });
    }
    const cancel = () => {
      router.push({ path: '/projectManagement' })
    }
    return {
      ...toRefs(state),
      submit,
      cancel,
      handleCheckedStockChange
    }
  }
}
</script>

<style lang="scss" scoped>
.add-container {
  .title {
    font-weight: bold;
  }
  .container {
    display: flex;
    align-items: center;
    padding: 20px 20px 0 0px;
    &__title {
      width: 120px;
      text-align: center;
    }
    &__input {
      width: 280px;
      margin-left: 30px;
    }
  }
  .btn-group {
    display: flex;
    padding: 20px;
    .btn {
      width: 100px;
      text-align: center;
      background: rgb(56, 162, 138);
      padding: 6px 0;
      border-radius: 3px;
      color: #fff;
      cursor: pointer;
      &.cancel {
        margin-left: 20px;
        background: #fff;
        color: #000;
        border: 1px solid #ddd;
      }
    }
  }
}
</style>